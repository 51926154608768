.modalWrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}

.toggleButtonWrapper {
  padding-top: 0rem;
  padding-bottom: 1.8rem;
  margin-left: 1rem;
  margin-right: 1rem;
}

.toggleButton {
  width: 100%;
  background: var(--red-clr);
  color: #ffffff;
  font-size: 2.2rem;
  font-weight: 700;
  padding-top: 1.5rem;
  padding-right: 2.2rem;
  padding-bottom: 1.5rem;
  padding-left: 2.2rem;
  border-radius: 9999px;
  border: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.buttonIconWrapper {
  width: 2rem;
  color: #ffffff;
  display: grid;
}

.modal {
  position: absolute;
  padding: 2.8rem;
  background-color: var(--red-clr);
  bottom: 0;
  color: #ffffff;
  border-top-left-radius: 2rem;
  border-top-right-radius: 2rem;
  transform: translateY(100%);
  transition: transform 1s ease;
  width: 100%;
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.closeButton {
  background-color: transparent;
  border: none;
  padding: 0;
}

.closeIconWrapper {
  width: 2rem;
  color: #ffffff;
}

.modalShow {
  transform: translateY(0);
}

.modal h2 {
  font-size: 2rem;
}

.modalBody {
  display: flex;
  flex-direction: column;
  row-gap: 1.8rem;
  font-size: 2rem;
  margin-top: 1.6rem;
}

.modalLinksWrapper {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  align-items: flex-start;
  margin-top: 1.8rem;
}

.modalLink {
  background-color: #ffffff;
  color: var(--red-clr);
  font-size: 1.8rem;
  font-weight: 700;
  padding-top: 1rem;
  padding-right: 2rem;
  padding-bottom: 1rem;
  padding-left: 2.2rem;
  text-decoration: none;
  display: flex;
  align-items: center;
  column-gap: 1.2rem;
  border-radius: 9999px;
}

.phoneIcon {
  width: 1.6rem;
}

.chatIcon {
  width: 1.6rem;
}

@media (min-width: 768px) {
  .toggleButtonWrapper {
    margin-left: 2rem;
    margin-right: 2rem;
  }
}

@media (min-width: 1025px) {
  .modalWrapper {
    display: none;
  } 
}