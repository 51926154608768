.stepsList {
  margin-left: 2rem;
  display: flex;
  flex-direction: column;
}

.stepWrapper {
  font-size: 1.8rem;
  line-height: 2.4rem;
  display: flex;
  align-items: flex-start;
  column-gap: 1.5rem;
  position: relative;
}

.stepWrapper:not(:last-child) {
  border-left: 1.5px solid var(--text-clr);
  padding-bottom: 2rem;
}

.stepNumber {
  position: absolute;
  top: 0;
  left: -2rem;
  display: flex;
  justify-content: center;
  min-width: 4rem;
  height: 4rem;
  border-radius: 4rem;
  background-color: var(--text-clr);
  color: #ffffff;
  font-weight: 700;
  line-height: 4rem;
}

.stepContent {
  padding-top: 0.8rem;
  margin-left: 3.5rem;
}

@media (min-width: 1025px) {
  .stepContent {
    font-size: 2.2rem;
    line-height: 3.3rem;
    padding-top: 0.4rem;
  }

  .stepWrapper:not(:last-child) {
    padding-bottom: 4rem;
  }

  .stepNumber {
    font-size: 2.2rem;
  }
}
