.topImageWrapper {
  height: 33rem;
  margin-bottom: 4rem;
}

.topImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  display: block;
}

.readMoreContainer {
  display: none;
}

main {
  padding: 0 1rem;
}

.topSection {
  margin-bottom: 6rem;
}

.topSection h1 {
  font-size: 2.5rem;
  font-weight: 500;
  line-height: 3rem;
  margin-bottom: 2rem;
}

.topSection p {
  font-size: 2rem;
  font-weight: 400;
  line-height: 2.8rem;
}

.stepsSection {
  --background-clr: #F6E199;
  
  margin-bottom: 6rem;
  background-color: var(--background-clr);
  border-radius: 3.2rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.stepsImageMobile {
  flex: 1;
  width: 100%;
  aspect-ratio: 1/1;
  display: block;
  object-fit: cover;
  object-position: center;
}

.stepsImage {
  display: none;
}

.stepsWrapper {
  flex: 1;
  padding-top: 3rem;
  padding-left: 2.8rem;
  padding-bottom: 3rem;
  padding-right: 2.8rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.startButton {
  display: flex;
  column-gap: 0.4rem;
  align-items: center;
  border: 2px solid var(--text-clr);
  background-color: var(--background-clr);
  color: var(--text-clr);
  font-size: 1.8rem;
  font-weight: 600;
  padding-top: 1rem;
  padding-left: 2rem;
  padding-bottom: 1rem;
  padding-right: 2.2rem;
  margin-top: 3.8rem;
  border-radius: 9999px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.startButton:hover {
  background-color: var(--text-clr);
  color: #ffffff;
}

.chevronIcon {
  width: 2.5rem;
}

.bold {
  font-weight: 700;
}

@media (min-width: 768px) {
  main {
    padding-right: 2rem;
    padding-left: 2rem;
  }
  
  .topImageWrapper {
    height: 40rem;
  }

  
}

@media (min-width: 1025px) {
  main {
    max-width: var(--desktop-width);
    margin-right: auto;
    margin-left: auto;
    margin-top: calc(var(--desktop-header-height) * -1);
    padding-right: 5rem;
    padding-left: 5rem;
    padding-top: calc(var(--desktop-header-height) + 2rem);
  }


  .stepsSection {
    flex-direction: row-reverse;
  }
  .topImageWrapper {
    height: calc(100vh - 10rem);
    margin-bottom: 4.6rem;
    position: relative;
  }

  .readMoreContainer {
    position: absolute;
    display: flex;
    align-items: center;
    height: 50px;
    bottom: 0;
    width: 100%;
    margin: 0 auto;
  }

  .readMoreLink {
    font-size: 2rem;
    background-color: #ffffff;
    margin: 0 auto;
    display: flex;
    align-items: center;
    column-gap: 0.8rem;
    padding: 1.6rem 4rem;
    font-size: 1.8rem;
    font-weight: 600;
    line-height: 2.4rem;
    border-radius: 1rem 1rem 0 0;
    transition: background-color 0.2s ease;
  }

  .readMoreLink:hover {
    background-color: #d9e7e3;
    color: var(--text-clr);
  }

  .arrowDownIcon {
    width: 1.6rem;
  }

  .topSection {
    margin-bottom: 6rem;
  }

  .topSection h1 {
    font-size: 5rem;
    font-weight: 500;
    line-height: 6rem;
  }

  .topSection p {
    font-size: 2.2rem;
    line-height: 3.3rem;
  }
  
  .stepsSection {
    flex-direction: row-reverse;
    margin-right: -5rem;
    margin-left: -5rem;
  }

  .stepsImage {
    display: block;
    width: 50%;
    aspect-ratio: 1/1;
  }

  .stepsImageMobile {
    display: none;
  }

  .stepsWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding: 5.7rem;
    padding: 4.8rem 5.6rem;
    max-width: 97.5rem;
  }

  .startButton {
    font-size: 2.2rem;
  }
}