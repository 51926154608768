.header {
  z-index: 10;
  box-shadow: 0 0.1875rem 0.375rem rgb(0 0 0 / 16%);
  height: 10rem;
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  background-color: #ffffff;
}

.headerInner {
  display: flex;
  justify-content: space-between;
  padding: 0 2.2rem;
}

.leftColumn {
  flex: 1;
}

.rightColumn {
  display: none;
  column-gap: 2rem;
}

.red113Logo {
  display: block;
  width: 21rem;
}

.link {
  display: inline-flex;
  align-items: center;
  column-gap: 1.2rem;
  padding: 1rem 2rem 1rem 2.2rem;
  border-radius: 9999px;
  background-color: var(--red-clr);
  font-size: 2.2rem;
  font-weight: 600;
  line-height: 3rem;
  color: #ffffff;
  text-decoration: none;
  transition: background-color 0.2s ease;
}

.link:hover {
  background-color: var(--red-dark-clr);
  color: #ffffff;
}

.link:visited {
  color: #ffffff;
}

.phoneIcon {
  width: 2rem;
}

.chatIcon {
  width: 2rem;
  padding-top: 0.4rem;
}

@media (min-width: 1025px) {
  .header {
    padding: 0 10.6rem;
  }
  
  .headerInner {
    margin-right: auto;
    margin-left: auto;
    width: var(--desktop-width);
    padding: 0;
    align-items: center;
  }

  .rightColumn {
    display: flex;
  }

  .red113Logo {
    width: 21rem;
  }
}