*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

:root {
  font-size: 62.5%;
  font-family: 'Avenir Next', sans-serif;

  --text-clr: #010000;
  --red-clr: #D61D28;
  --red-dark-clr: #B21821;
  --blue-light-clr: #E8F1EE;
  --footer-bg-clr: #F3F7F6;
  --photo-upload-bg-clr: #E6E6E6;

  --logo-width-mobile: 14.6rem;
  --logo-width-desktop: 20rem;

  --desktop-width: 102.8rem;
  --desktop-header-height: 10rem;
}

body {
  margin: 0;

  color: var(--text-clr);

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.page-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

a {
  color: var(--text-clr);
  background-color: transparent;
  text-decoration: none;
}

a.underline {
  border-bottom: 2px solid var(--text-clr);
}

a:hover {
  color: var(--red-clr);
  border-color: var(--red-clr);
}

p {
  margin: 0;
}

ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

ul li {
  padding-right: 0;
  padding-left: 0;
}

button,
input,
optgroup,
select,
textarea {
    margin: 0;
    font-family: inherit;
    font-size: 100%;
    line-height: 1.15;
}

button,
input {
    overflow: visible;
}

button,
select {
    text-transform: none;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
    padding: 0;
    border-style: none;
}

h1, h2 {
  margin: 0;
}

.cropper-view-box {
  outline-width: 0px!important;
}

#result-container {
  z-index: -1;
  pointer-events: none;
  position: fixed;
}