.topLine {
  display: flex;
  font-size: 1.6rem;
  line-height: 2.4rem;
  column-gap: 0.8rem;
  align-items: center;
}

.bold {
  font-weight: 700;
}

.warningIcon {
  width: 2rem;
  color: #2CA982;
}

.headsUpParagraph {
  flex: 1;
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-weight: 400;
}

@media (min-width: 1025px) {
  .headsUpWrapper {
    flex: 1;
  }

  .topLine {
    font-size: 2rem;
    line-height: 3.2rem;
    column-gap: 1rem;
  }

  .warningIcon {
    width: 2.4rem;
  }

  .headsUpParagraph {
    font-size: 2rem;
    line-height: 3.2rem;
  }
}