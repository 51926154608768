.footer {
  flex: 1;
  background-color: var(--footer-bg-clr);
  padding: 7.5rem 3.2rem 9rem;
}

.logoWrapper {
  width: var(--logo-width-mobile);
  margin-bottom: 5rem;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 4rem;
}

.contactParagraph {
  margin-bottom: 2.8rem;
  font-size: 2.5rem;
  font-weight: 500;
  flex: 1;
}

.headsUpWrapper {
  flex: 1;
}

.navWrapper {
  display: flex;
  flex-direction: column;
}

.socialNavParagraph {
  margin-bottom: 1.6rem;
  font-size: 1.4rem;
  font-weight: 400;
}

.socialLinkList {
  display: flex;
  column-gap: 3rem;
}

.socialLinkList a:hover {
  color: var(--text-clr);
}

.socialIcon {
  display: block;
  width: 2.6rem;
}

@media (min-width: 1025px) {
  .headsUpWrapper {
    max-width: 60%;
  }

  .footer {
    padding-top: 6rem;
    padding-left: 5rem;
    padding-bottom: 7rem;
    padding-right: 5rem;
  }
  
  .footerWrapper {
    max-width: var(--desktop-width);
    margin: 0 auto;
  }

  .logoWrapper {
    width: var(--logo-width-desktop);
    margin-bottom: 5.5rem;
  }

  .contentWrapper {
    flex-direction: row;
    column-gap: 12rem;
  }

  .contactParagraph {
    flex: 1;
    font-size: 3.2rem;
    margin-bottom: 5.5rem;
  }

  .footerParagraphWrapper {
    flex: 1;
  }

  .footerParagraph {
    font-size: 2.2rem;
  }

  .navWrapper {
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    column-gap: 3rem;
  }

  .socialNav {
    margin-bottom: 0;
  }

  .socialNavParagraph {
    font-size: 1.6rem;
  }

  .socialIcon {
    width: 3.4rem;
  }
}