@font-face {
  font-family: "Avenir Next";
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url('./fonts/avenir-next-lt-pro-regular.woff2') format('woff2'),
    url('./fonts/avenir-next-lt-pro-regular.woff') format('woff');
}

@font-face {
  font-family: "Avenir Next";
  font-weight: 400;
  font-style: italic;
  font-display: swap;
  src: url('./fonts/avenir-next-lt-pro-regular-italic.woff2') format('woff2'),
    url('./fonts/avenir-next-lt-pro-regular-italic.woff') format('woff');
}

@font-face {
  font-family: "Avenir Next";
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  src: url('./fonts/avenir-next-lt-pro-medium.woff2') format('woff2'),
    url('./fonts/avenir-next-lt-pro-medium.woff') format('woff');
}

@font-face {
  font-family: "Avenir Next";
  font-weight: 600;
  font-style: normal;
  font-display: swap;
  src: url('./fonts/avenir-next-lt-pro-demi-bold.woff2') format('woff2'),
    url('./fonts/avenir-next-lt-pro-demi-bold.woff') format('woff');
}

@font-face {
  font-family: "Avenir Next";
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: url('./fonts/avenir-next-lt-pro-bold.woff2') format('woff2'),
    url('./fonts/avenir-next-lt-pro-bold.woff') format('woff');
}